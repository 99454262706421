<template>
    <div>
        <auth-edit ref="authEdit" :editType="1" :userId="userId" :status.sync="status"></auth-edit>
        <div class="page-group-box MT10">
            <el-button @click="goBack">返回</el-button>
            <el-button type="primary" @click="onSubmit" :loading="btnLoading" :disabled="status == 1 ? true : false">提交审核</el-button>
        </div>
    </div>
</template>

<script>
import AuthEdit from '@/components/AuthEdit'
export default {
    components:{
        AuthEdit
    },
    data(){
        return{
            btnLoading:false,
            userId: this.$store.state.userInfo.id,
            status: 0
        }
    },
    methods:{
        goBack(){
            this.$router.go(-1);
        },
        async onSubmit(){
            this.btnLoading = true;
            if(this.status == 2){
                this.$confirm('是否确认提交新的认证信息?<br/>在新的认证信息通过审核之前,您的账号认证信息将被视为无效', '修改认证信息', {
                    // type: 'warning',
                    confirmButtonText: '提交',
                    showCancelButton:false,
                    showClose:false,
                    customClass:'mpop',
                    dangerouslyUseHTMLString:true,
                    closeOnClickModal: false
                }).then( async() => {
                    await this.$refs.authEdit.onSubmit();
                    this.btnLoading = false;
                }).catch(() => {
                    this.btnLoading = false;
                }); 
            }else{
                await this.$refs.authEdit.onSubmit();
                this.btnLoading = false;
            }
        }
    }
}
</script>

<style lang="scss" scoped>

</style>